import React, { useState } from "react";
import {
    Box,
    Typography,
    IconButton,
    Link,
    Container,
    Button,
    TextField
} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import Grid from "@mui/material/Grid2";
import { useDb } from "src/contexts/DbContext";
import { useStyles } from "src/contexts/StylesContext";
import Header from 'src/components/Header';
import Footer from 'src/components/Footer';
import { AccessTime, Email, LocationOn, Phone } from "@mui/icons-material";
import {useAuth} from "../../contexts/AuthContext";
import PageHelmet from '../../components/PageHelmet';



/* interface HomePageProps {
    isLoggedIn: boolean;
} */

const ContactUs: React.FC = () => {
    const { buttonStyleOrange, buttonStyleGreen } = useStyles();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const {me, isAuthenticated} = useAuth();

    const menuItems = [
        { label: 'Home', link: '/' },
        { label: 'About Us', link: '/about-us' },
        { label: 'Sign Up', link: '/register' },
        ...(isAuthenticated
          ? [
              /* { label: 'Profile', link: '/profile' }, */
              /* { 
                label: 'Profile', 
                link: `/management/profile/settings/${me.id}`, 
                isDynamic: true
              }, */
            ]
          : []),
    ];

    const toggleDrawer = (open: boolean) => {
        setIsDrawerOpen(open);
    };

    const {t} = useDb();


  return (
    <>
    <PageHelmet />
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1100,
      }}
    >
      <Header menuItems={menuItems} />
    </Box>

    {/* Spazio per compensare l'header fisso */}
    <Box sx={{ height: '150px' }} />
   
    <Box sx={{ padding: "1rem 0", backgroundImage: 'url(static/images/home/bg.jpg)',
        backgroundRepeat: 'repeat' }}>
        <Grid container spacing={2}>
            <Grid size={12} textAlign="center" sx={{mb:8}}>
                <Typography variant="h2" sx={{ padding: "3rem 0", mb: 0, textTransform: "uppercase", fontSize: "35px", fontFamily: "'Oswald', sans-serif", fontWeight: 500 }}>Contact Us</Typography>
                <img src="static/images/home/line.png" alt="Line" />
            </Grid>
        </Grid>
    </Box>

    {/* Contact Us Section */}
    <Box component="section" sx={{ padding: "1rem 0", backgroundImage: 'url(static/images/home/bg.jpg)',
        backgroundRepeat: 'repeat'}} py={4}>
        <Container>
          <Grid container spacing={8}>
            <Grid size={{ xs: 12, sm: 6 }} sx={{ mb: 8}}>
              <Box>
                <Typography variant="h3" gutterBottom sx={{mb: 2, textTransform: "uppercase", fontFamily: "'Oswald', sans-serif", fontWeight: 500}}>
                  Send Question
                </Typography>
                <form>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      label="Name"
                      variant="outlined"
                      name="fname"
                      id="fname"
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      label="Email"
                      variant="outlined"
                      type="email"
                      name="email"
                      id="email"
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      label="Phone"
                      variant="outlined"
                      type="tel"
                      name="tel"
                      id="phone"
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      label="Comment"
                      variant="outlined"
                      multiline
                      rows={4}
                      name="review"
                      id="w3review"
                    />
                  </Box>
                  <Box>
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{color: "#fff", textTransform: "uppercase", fontFamily: "'Oswald', sans-serif", fontWeight: 500}}>
                      Send Request
                    </Button>
                  </Box>
                </form>
              </Box>
            </Grid>

            <Grid size={{ xs: 12, sm: 6 }}>
              <Box>
                <Typography variant="h3" gutterBottom sx={{mb: 2, textTransform: "uppercase", fontFamily: "'Oswald', sans-serif"}}>
                  Reach Us
                </Typography>

                <Box display="flex" alignItems="center" mb={2}>
                  <LocationOn color="primary" sx={{ mr: 2 }} />
                  <Typography>25 West 21th Street, Miami FL, USA</Typography>
                </Box>

                <Box display="flex" alignItems="center" mb={2}>
                  <Phone color="primary" sx={{ mr: 2 }} />
                  <Typography>Ph: +123 488 9652</Typography>
                </Box>

                <Box display="flex" alignItems="center" mb={2}>
                  <Email color="primary" sx={{ mr: 2 }} />
                  <Typography>info@thespeciality.com</Typography>
                </Box>

                <Box display="flex" alignItems="center" mb={2}>
                  <AccessTime color="primary" sx={{ mr: 2 }} />
                  <Typography>Monday to Saturday 10:00 AM to 7:00 PM</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Contact Map Section */}
      <Box component="section">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d27433.72228290711!2d76.72532580000001!3d30.740453700000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1733299842147!5m2!1sen!2sin"
          width="100%"
          height="550"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Box>

    
    <Footer menuItems={menuItems} />
    </>
  );
};

export default ContactUs;
