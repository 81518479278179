import React, { createContext, useContext } from "react";

export interface Styles {
  buttonStyleOrange: React.CSSProperties;
  buttonStyleGreen: React.CSSProperties;
}

const StylesContext = createContext<Styles | undefined>(undefined);

export const StylesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const buttonStyleOrange: React.CSSProperties = {
    background: "#709a02",
    padding: "11px 40px",
    fontSize: "25px",
    marginTop: "6px",
    display: "inline-block",
    borderRadius: "10px",
    borderBottom: "solid 9px #ff6600",
    color: "#000000",
    textTransform: "uppercase",
    fontFamily: "'Oswald', sans-serif",
    fontWeight: 500,
  };

  const buttonStyleGreen: React.CSSProperties = {
    background: "#ffffff",
    padding: "2px 40px",
    fontSize: "16px",
    marginTop: "6px",
    display: "inline-block",
    borderRadius: "18px",
    borderBottom: "solid 9px #017432",
    color: "#000000",
    textTransform: "uppercase",
    fontFamily: "'Oswald', sans-serif",
    fontWeight: 500,
  };

  return (
    <StylesContext.Provider value={{ buttonStyleOrange, buttonStyleGreen }}>
      {children}
    </StylesContext.Provider>
  );
};

export const useStyles = (): Styles => {
  const context = useContext(StylesContext);
  if (!context) {
    throw new Error("useStyles must be used within a StylesProvider");
  }
  return context;
};
