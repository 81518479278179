import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import {useNavigate} from 'react-router-dom';
import {Typography} from '@mui/material';
import {Validation, ValidationType, Validator} from "../../utils/validate";
import {useAuth} from "../../contexts/AuthContext";
import SuspenseLoader from "../SuspenseLoader";
import {useUsers} from "../../contexts/UsersContext";
import {Log} from "../../utils/utils";
import {useDb} from "../../contexts/DbContext";
import {AxiosError} from "axios";


export interface Form {
    email?: Field;
    password?: Field;
}

export interface Field {
    value?: string | boolean;
    error?: string;
}

export interface FormValidation {
    email: Validator[];
    password: Validator[];
}

export default function Login() {
    const [form, setForm] = useState<Form>({});
    const {t} = useDb();

    const getSignInErrorMessage = (error: AxiosError) => {
        let errorMessage: string | null = null;
        const errorNum = error.response?.status;
        const simpleData = error.response?.data as {
            status: number;
            detail: string;
        }
        const detailErr = simpleData?.detail;

        if (detailErr) {
            if (detailErr.includes('Invalid Token'))
                return t('The inserted token is not correct');
            if (detailErr.includes('already registered'))
                return t('User is already registered')
            return detailErr;
        }

        switch (errorNum) {
            case 401:
                errorMessage = t('User unrecognized or wrong password');
                break;
            default:
                errorMessage = t('An issue occurred on backend ') + '('+ errorNum + ')';
                break;
        }

        return errorMessage;
    }

    const formValidation: FormValidation = {
        email: [
            {
                validatorType: ValidationType.REQUIRED,
                message: t('This is a required field')
            },
            {
                validatorType: ValidationType.EMAIL,
                message: t('Email format is wrong')
            }
        ],
        password: [
            {
                validatorType: ValidationType.REQUIRED,
                message: t('This is a required field')
            }
        ],
    }

    const updateForm = (key: string, validators: Validator[], value: string | boolean) => {
        const errors: Validator[] = Validation(value, validators).errors;

        setForm({
            ...form,
            [key]: {
                value,
                error: ''
            }
        })
        setErr(null);
    }

    const clearForm = () => {

        setForm({
            ...form,
            email: {
                value: '',
                error: ''
            },
            password: {
                value: '',
                error: ''
            }
        })
    }

    const [err, setErr] = useState<string | null>(null);
    const {me,refreshMe,error, isLoading, login,isAuthenticated} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated){
            Log.debug('Login effect: isAuthenticated');
            if (me){
                Log.debug('Login effect: me is ok->navigate');
                navigate(`/management/profile/settings/${me.id}`,{replace:true});
                /* navigate(`/profile`); */
            }
            else{
                Log.debug('Login effect: me is ko->refresh');
                refreshMe();
            }
        }
    }, [me, isAuthenticated, refreshMe]);

    useEffect(() => {

        if (error){
            setErr(getSignInErrorMessage(error));
        }
        else{
            setErr(null);
        }
    }, [error]);

    const onSubmit = async (e) => {
        e.preventDefault();
        const emailErrors: Validator[] = Validation(form.email?.value, formValidation.email).errors;
        const passwordErrors: Validator[] = Validation(form.password?.value, formValidation.password).errors;
        setForm({
            ...form,
            email: {...form.email, error: emailErrors.length > 0 ? emailErrors[0].message : ''},
            password: {...form.password, error: passwordErrors.length > 0 ? passwordErrors[0].message : ''},
        })
        if (emailErrors.length === 0 && passwordErrors.length === 0) {
            Log.debug('Form submitted!')
            login(form.email?.value as string, form.password?.value as string);
        }
    }

    return (
        <Container component="main">
            {isLoading && (<SuspenseLoader/>)}
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

                {err && (<Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography sx={{mt: 2, color: '#bf4611',fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}>{err}</Typography>
                </Box>)}

                <Box component="form" noValidate sx={{mt: 1, p: 0,fontFamily: 'Oswald, sans-serif'}} onSubmit={onSubmit}>
                    <TextField sx={{mb: 3}}
                        InputProps={{
                            style: { fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                        }}
                        InputLabelProps={{
                            style: { fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                        }}
                        FormHelperTextProps={{
                            style: { fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                        }}
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        type="text"
                        variant="standard"
                        value={form?.email?form.email.value:''}
                        onBlur={(element) => updateForm('email', formValidation.email, element.target.value)}
                        onChange={(element) => updateForm('email', formValidation.email, element.target.value)}
                        error={!!form.email?.error}
                        helperText={form.email?.error}
                    />
                    <TextField sx={{mb: 5}}
                        InputProps={{
                            style: { fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                        }}
                        InputLabelProps={{
                            style: { fontFamily: 'Oswald, sans-serif', fontSize: '16px'} // Cambia il font
                        }}
                        FormHelperTextProps={{
                            style: { fontFamily: 'Oswald, sans-serif', fontSize: '14px'} // Cambia il font
                        }}
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        label="Password"
                        name="password"
                        type="password"
                        variant="standard"
                        value={form?.password?form.password.value:''}
                        onBlur={(element) => updateForm('password', formValidation.password, element.target.value)}
                        onChange={(element) => updateForm('password', formValidation.password, element.target.value)}
                        error={!!form.password?.error}
                        helperText={form.password?.error}
                    />

                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{color: "#fff", mb: 5, textTransform: "uppercase", fontFamily: "'Oswald', sans-serif", fontWeight: 500}}>
                        {t('Log In')}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}
