import {ChangeEvent, forwardRef, ReactElement, Ref, useEffect, useState} from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  List,
  Slide,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {TransitionProps} from '@mui/material/transitions';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import PersonIcon  from '@mui/icons-material/Person';
import {OverridableStringUnion} from "@mui/types";
import {SvgIconPropsSizeOverrides} from "@mui/material/SvgIcon/SvgIcon";
import SearchItem from "./../SearchItem";
import IconMale from '../../../../../../images/avatars/male-2.png'
import {search, searchUsers, searchUsersBySkill} from "../../../../../../services/userService";
import {useAuth} from "../../../../../../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import {Log} from "../../../../../../utils/utils";
import {useDb} from "../../../../../../contexts/DbContext";
import {Skill} from "../../../../../../types/Skill";
import {searchSkills} from "../../../../../../services/skillService";
import {PagedRequest} from "../../../../../../types/PagedRequest";

const StyledSearchItem = styled(SearchItem)(({ theme }) => ({
    '& .MuiTypography-primary': {
      color: '#FF5733', // Colore personalizzato per primary
      fontWeight: 'bold',
    },
  }));

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`
);

const SearchInputWrapper = styled(TextField)(
  ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};

    .MuiInputBase-input {
        font-size: ${theme.typography.pxToRem(17)};
        font-family: 'Oswald', sans-serif;
    }
    
    .MuiInputLabel-root {
        font-size: ${theme.typography.pxToRem(17)};
        font-family: 'Oswald', sans-serif;
    }
    
`
);

const DialogTitleWrapper = styled(DialogTitle)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(3)}
`
);

function HomeSearch({fontSize='large'}) {

  const [openSearchResults, setOpenSearchResults] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  
  const [results, setResults] = useState([]);
  // const [projects, setProjects] = useState([]); TODO: // uncomment when feature will be available
  const [skills,setSkills] = useState([]);
  const {isAuthenticated} = useAuth();
  const {t} = useDb();
  const navigate = useNavigate();

  useEffect(() => {

    const loadPublicUser = async (s:string)=>{

      let users_arr = [];

      try {

        users_arr = await searchUsersBySkill(s);
        setResults(users_arr);

      }
      catch(err){
        Log.error(err);
      }

      try {
        const arg: PagedRequest = {
          "page": 0,
          "size": 0,
          "filter": s,
        }
        const response = await search(arg);
        if (response) {
          users_arr = users_arr.concat(response.items);
        }
      }
      catch(err){
        Log.error(err);
      }

      try{
        const filteredSkills = await searchSkills(s);
        setSkills(filteredSkills);
      }
      catch (err){
        Log.error(err);
        setSkills([]);
      }

      setResults(users_arr);

    }

    if (searchValue.length>2){
      loadPublicUser(searchValue);
    }
    else{
      setResults([]);
      setSkills([]);
    }

  }, [searchValue]);


  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
      if (event.target.value) {
        if (!openSearchResults) {
          setOpenSearchResults(true);
          
        }
      } else {
        setOpenSearchResults(false);
      
      }
  };

  const handleBlur = () => {
    /* setSearchValue(""); 
    setOpenSearchResults(false); */
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickSelectedSearchItem = (id: string, type: string)=>{
    Log.debug('Selected id: ' + id + ' and type is: ' + type );
    navigate(`/contact-us`);
    if (isAuthenticated){
      navigate(`/management/profile/details/${id}`);
    }
    else{
      navigate(`/register?popup=true`);
    }
  }
  

  return (
    <>
      
        <SearchInputWrapper
            value={searchValue}
            type="text"
            autoFocus={true}
            onChange={handleSearchChange}
            onBlur={handleBlur}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <SearchTwoToneIcon />
                </InputAdornment>
                ),
            }}
            placeholder={t('Search for professional...')}
            fullWidth
            /* label={t('Search')} */
            sx={{
                border: '2px solid #ff6600', // Colore del bordo (blu di default di MUI)
                borderRadius: '12px', // Aggiungi un raggio per bordi arrotondati, se desideri
            }}
        />

            

        {/* </DialogTitleWrapper> */}
        <Divider />

        {openSearchResults && (
          <DialogContent sx={{
            backgroundColor: '#000',
            position: 'relative',
            zIndex: 1000, 
          }}>
            <Box
              sx={{ pt: 0, pb: 1 }}
              display="flex"
              justifyContent="space-between"
            >
              <Typography variant="body2" component="span" sx={{fontFamily: 'Oswald, sans-serif'}}>
                {t('Search')} {' '}
                <Typography
                  sx={{ fontWeight: 'bold' , fontFamily: 'Oswald, sans-serif' }}
                  variant="body2"
                  component="span"
                >
                  {searchValue}
                </Typography>
              </Typography>
              {/*{isAuthenticated && ( TODO:// Uncomment when feature will be in place*/}
              {/*    <Link href="#" variant="body2" underline="hover" sx={{fontFamily: 'Oswald, sans-serif'}}>*/}
              {/*  Ricerca avanzata*/}
              {/*</Link>)}*/}
            </Box>
            <Divider sx={{ my: 1 }} />
            { (results && searchValue.length>2) && (<Box sx={{textAlign: 'start'}}>
              <Typography
                  sx={{fontFamily: 'Oswald, sans-serif'}}
                  component="span"
                  variant="body2"
              >
                {t('Users found')}: {results.length}
              </Typography>
            </Box>)}
            {/* <Divider sx={{ my: 1 }} /> */}
            <List disablePadding>
              {results
                  ? (results.map((user) => {
                    return (
                        <div key={user.id} onClick={() => handleClickSelectedSearchItem(user.id,'user')}>
                        <Divider sx={{ my: 1 }} component="li" />
                        <StyledSearchItem avatar={IconMale} title={user.firstname + ' ' + user.lastname} sx={{color: '#fff'}} subtitle={user.skills.map((s)=>t(s)).join(' ')}/>
                        
                        </div>
                    );
                  }))
                  : <></>}
            </List>
            <Divider sx={{ my: 1 }} />
            { (skills && searchValue.length>2) && (<Box sx={{textAlign: 'start'}}>
              <Typography
                  sx={{fontFamily: 'Oswald, sans-serif'}}
                  component="span"
                  variant="body2"
              >
                {t('Skills found')}: {skills.length}
              </Typography>
            </Box>)}
            {/* <Divider sx={{ my: 1 }} /> */}
            <List disablePadding>
              {skills
                  ? (skills.map((skill) => {
                    return (
                        <div key={skill.id} onClick={() => Log.debug('Click Skill result')}>
                          <Divider sx={{ my: 1 }} component="li" />
                          <SearchItem avatar={null} subtitle={''} title={t(skill.name)}/>
                          
                        </div>
                    );
                  }))
                  : <></>}
            </List>
            {/*<Divider sx={{ mt: 1, mb: 2 }} />*/}
            {/*<Box sx={{ textAlign: 'center'}}> TODO:// Uncomment when feature will be in place */}
            {/*  <Button color="primary">*/}
            {/*    <Typography*/}
            {/*        sx={{fontFamily: 'Oswald, sans-serif' }}*/}
            {/*        component="span"*/}
            {/*        variant="body2"*/}
            {/*    >*/}
            {/*      Tutti i risultati...*/}
            {/*    </Typography>*/}
            {/*  </Button>*/}
            {/*</Box>*/}
          </DialogContent>
        )}
      {/* </DialogWrapper> */}
    </>
  );
}

export default HomeSearch;
