import React, {useEffect}  from "react";
import {
    Box,
    Typography,
    IconButton,
    Link,
    Container,
    Button,
    TextField
} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import Grid from "@mui/material/Grid2";
import { useDb } from "src/contexts/DbContext";
import { useStyles } from "src/contexts/StylesContext";
import Header from 'src/components/Header';
import Footer from 'src/components/Footer';
import {useAuth} from "../../contexts/AuthContext";
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet-async";
import SubscriptionSelect from "../../components/SubscriptionSelect";
import {Log} from "../../utils/utils";

const Subscription: React.FC = () => {

    const {me, refreshMe, isAuthenticated} = useAuth();
    const {t} = useDb();
    const [selected, setSelected] = React.useState<string>(null);

    useEffect(() => {
        if (!me)
            refreshMe();
    }, [me]);

    const navigate = useNavigate();

    const handleSkipButtonClick = ()=>{
        Log.debug("handleSkipButtonClick");
        if (me && isAuthenticated){
            navigate(`/management/profile/details/${me.id}`,{replace:true});
        }
        else{
            navigate('/');
        }
    }

    const handleSubscribeButtonClick = (id: string)=>{
        Log.debug('Selected: ' + id);
        
        navigate(`/subscribe/checkout/${id}`, {replace:true});
        
    }

    const menuItems = [
        { label: 'Home', link: '/' },
        ...(me && isAuthenticated
            ? [
                /* { 
                    label: 'Profile', 
                    link: `/management/profile/settings/${me.id}`, 
                    isDynamic: true
                }, */
              ]
            : []),
        { label: 'Contact Us', link: '/contact-us' },
    ];

   /*  const {t} = useDb(); */

    /* const navigate = useNavigate();
    const handleClick = () => {
        navigate('/sign-in');
        window.scrollTo(0, 0);
    }; */


  return (
    <>
    <Helmet>
        <title>The Specialist - {t('Subscriptions')}</title>
    </Helmet>

    <Header menuItems={menuItems} />

    <Box sx={{ padding: "0rem 0", backgroundImage: 'url(static/images/home/bg.jpg)',
        backgroundRepeat: 'repeat', }}>
        <Grid container spacing={2}>
            <Grid size={12} textAlign="center">
                <Typography variant="h2" 
                sx={{ padding: "3rem 0", 
                mb: 0, 
                textTransform: "uppercase", 
                fontSize: "35px", 
                fontFamily: "'Oswald', sans-serif", 
                fontWeight: 500 }}>Subscription</Typography>
                <img src="static/images/home/line.png" alt="Line" />
            </Grid>
        </Grid>
    </Box>
   
    <Box sx={{ padding: "4rem 0", backgroundImage: 'url(static/images/home/bg.jpg)',
    backgroundRepeat: 'repeat', }}>
        <Box>
            <Grid container spacing={2}>
                <Grid size={12} textAlign="center">
                    <Box className="find-movie-content">
                    <Typography variant="h2" sx={{ mb: 2, textTransform: "uppercase", fontFamily: "'Oswald', sans-serif", fontWeight: 500 }}>
                        {t('Select your Subscription')}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 3, fontSize: "22px",
                    padding: "20px 0",
                    color: "#ff6600", }}>
                        {t('Choose your favourite formula')}
                    </Typography>
                    
                    </Box>
                </Grid>
            </Grid>
        </Box>
        
        <SubscriptionSelect onChange={(s) => {
                setSelected(s);
        }}/>

        <Box sx={{display: 'flex', flexDirection: 'row', alignItems:'center' ,justifyContent: 'center', width: '100%', mt:4}}>

            {selected && (
                <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    sx={{mt: 0, mb: 2, mr: 2, fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}
                    onClick={(e) => handleSubscribeButtonClick(selected)}
                >
                    {t('Select')}
                </Button>
            )}

            <Button
                size="large"
                variant="contained"
                type="submit"
                color="primary"
                sx={{mt: 0, mb: 2, mr: 0, ml:2, fontFamily: 'Oswald, sans-serif', fontSize: '16px'}}
                onClick={(e) => handleSkipButtonClick()}
            >
                {t('Skip for now')}
            </Button>

        </Box>
        
        

        {/* <Box sx={{ padding: "3rem 0" }}>
            <Container>
                <Grid container spacing={6}>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <Typography variant="body1">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                        </Typography>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <Typography variant="body1">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>                
        <Box sx={{ padding: "2rem 0" }}>
            <Container>
                <Box sx={{ padding: "2rem 0", backgroundImage: 'url(static/images/home/about-bg.jpg)' }}>
                    <Container>
                        <Grid container spacing={4}>
                            <Grid size={{ xs: 12, sm: 6 }}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <img
                                    src="static/images/home/about-img.jpg"
                                    alt="About Us"
                                    style={{ maxWidth: '100%', borderRadius: 8 }}
                                    />
                                </Box>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6 }}>
                                <Box sx={{ padding: 2 }}>
                                    <Typography variant="body1" sx={{ lineHeight: 1.8, color: "#000", fontSize: "16px" }}>
                                    Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Container>
        </Box> */}
    </Box>
    
    <Footer menuItems={menuItems} />
    </>
  );
};

export default Subscription;
