import {useParams} from "react-router";
import {useAuth} from "../../contexts/AuthContext";
import React, {forwardRef, ReactElement, Ref, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
/* import Footer from "../../components/Footer/index_old"; */
import Box from "@mui/material/Box";
import {Chip, Dialog, DialogTitle, IconButton, Slide, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Container from "@mui/material/Container";
import {ContentStats} from "../users/profile/OverviewPage";
import {getContentMetadata, getContentStats, getProjectStats, Log} from "../../utils/utils";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import {styled} from "@mui/material/styles";
import {TransitionProps} from "@mui/material/transitions";
import ModalDialog from "../../components/ModalDialog";
import {ContentStatsCard} from "../../components/ContentStatsCard";
import {Project} from "../../types/Project";
import {getProjectById} from "../../services/projectService";
import {EditProjectPage} from "../users/settings/EditProjectPage";
import {refreshTokenIfNeeded} from "../../services/authService";
import ScrollableHtml from "../../components/ScrollableHtml";
import SuspenseLoader from "../../components/SuspenseLoader";
import {useDb} from "../../contexts/DbContext";
import PageFooter from "../../components/PageFooter";

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
    () => `
    .MuiDialog-container {
        height: auto;
        display: flex;
        align-items: center; 
        justify-content: center;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`
);

const DialogTitleWrapper = styled(DialogTitle)(
    ({theme}) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(3)}
`
);

function ProjectDetails() {

    const {id} = useParams();
    const {me,refreshMe} = useAuth();
    const {t} = useDb();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentProject, setCurrentProject] = useState<Project>(null);
    const [contentStats, setContentStats] = useState<ContentStats>(null);
    const [showModal, setShowModal] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [open, setOpen] = useState(false);

    useEffect(() => {

        const loadBackendData = async (id)=>{

            try{

                setIsLoading(true);
                await refreshTokenIfNeeded();

                if (id){
                    const project = await getProjectById(id);
                    setCurrentProject(project);
                    setContentStats(getContentStats(project.contents));
                    Log.debug('======PROJECT======');
                    Log.debug(project);
                }
                setIsLoading(false);
            }
            catch (error){
                setIsLoading(false);
                setShowModal(true);
                setErrorMessage(error.message);
            }

        }

        loadBackendData(id);

    }, [id]);

    useEffect(() => {
        if (!me){
            refreshMe();
        }
    }, [me]);

    const handleClose = () => {
        setShowModal(false);
        setErrorMessage("");
    };

    const start = currentProject?.start?new Date(currentProject?.start):null;
    const end = currentProject?.end?new Date(currentProject?.end):null;

    return (
        <>
            <Helmet>
                <title> {currentProject
                    ? currentProject.title
                    : ''} </title>
            </Helmet>
            {isLoading && (<SuspenseLoader/>)}
            {showModal && (
                <ModalDialog open={showModal} onClose={handleClose} label={errorMessage} id={'error'} title={t('Error')}/>
            )}
            <DialogWrapper
                open={open}
                TransitionComponent={Transition}
                keepMounted
                maxWidth="sm"
                fullWidth
                scroll="paper"
                onClose={handleClose}
            >
                <DialogTitleWrapper>
                    {open && (<EditProjectPage projectId={currentProject?.id} OnAbort={()=>setOpen(false)} OnSuccess={()=>setOpen(false)} />)}
                </DialogTitleWrapper>
            </DialogWrapper>
            <Container sx={{mt: 2}} maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="start"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid size={{xs:12, md:12}} >
                        <Box
                            p={3}
                            display="flex"
                            alignItems="start"
                            flexDirection='column'
                            justifyContent="space-between"
                        >

                            <Box display='flex'>
                                <Typography variant="h1" sx={{fontFamily: 'Oswald'}}>
                                    {currentProject?.title}
                                </Typography>
                                <Box display="flex" justifyContent="end" sx={{ml: 'auto'}}>
                                    {(currentProject?.profile?.members?.filter((member)=>{
                                        return member.userId===me?.id && member.role.name==='Owner';
                                    })) && (<IconButton sx={{margin: -0.4}} onClick={() => {
                                        setOpen(true);
                                    }
                                    }>
                                        <EditTwoToneIcon fontSize="large"/>
                                    </IconButton>)}
                                </Box>
                            </Box>

                            <Typography variant="subtitle2" sx={{fontFamily: 'Oswald', fontSize: 24}}>
                                {currentProject?.profile?.name}
                            </Typography>

                            <Box
                                sx={{
                                    py: 2,
                                    mb: 4
                                }}
                            >
                                <Box display="flex" justifyContent="start">

                                    <Typography variant="h4" color="text.primary"
                                                sx={{fontFamily: 'Oswald, serif', mt: 0.2}}>
                                        {t('Skills')}:
                                    </Typography>

                                    {currentProject?.skills?.map((option) => (
                                        <Chip
                                            key={option.id}
                                            sx={{
                                                mr: 0.5,
                                                fontFamily: 'Oswald, serif',
                                                fontSize: '16px'
                                            }}
                                            size="small"
                                            label={t(option.name)}
                                            color="primary"
                                            onClick={() => Log.debug('Click')}
                                        />
                                    ))}

                                </Box>

                            </Box>

                            <Box display="flex" justifyContent="start" width={'100%'}>

                                <Typography variant="h4" color="text.primary" sx={{fontFamily:'Oswald, serif', mt:0.4, mr:2}}>
                                    {t('Start')}: {start?.toLocaleDateString()}
                                </Typography>

                                <Typography variant="h4" color="text.primary" sx={{fontFamily:'Oswald, serif', mt:0.4}}>
                                    {end?t('End')+': ' + end.toLocaleDateString():t('In Progress...')}
                                </Typography>

                            </Box>

                            <Typography variant="h3" color="text.primary"
                                        sx={{fontFamily: 'Oswald, serif', mt: 3}}>
                                {t('Description')}
                            </Typography>

                            <ScrollableHtml htmlContent={currentProject?.description} width={'100%'} height={'200px'} marginTop={'20px'} border={false} font={'Oswald'}/>

                            <Grid size={{xs:12, md:4}}  sx={{display: "flex", justifyContent: 'center'}}>
                                <ContentStatsCard contentStats={contentStats}
                                                  sx={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}/>
                            </Grid>

                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <PageFooter/>
        </>
    );
}

export default ProjectDetails;
