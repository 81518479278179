import React, { useState } from "react";
import {
    Box,
    Typography,
    IconButton,
    Link,
    Container,
    Button,
    TextField
} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import Grid from "@mui/material/Grid2";
import { useDb } from "src/contexts/DbContext";
import { useStyles } from "src/contexts/StylesContext";
import Header from 'src/components/Header';
import Footer from 'src/components/Footer';
import {useAuth} from "../../contexts/AuthContext";
import { useNavigate } from 'react-router-dom';
import PageHelmet from '../../components/PageHelmet';


/* interface HomePageProps {
    isLoggedIn: boolean;
} */

const AboutUs: React.FC = () => {
    const { buttonStyleOrange, buttonStyleGreen } = useStyles();
    
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const {me, isAuthenticated} = useAuth();

    const menuItems = [
        { label: 'Home', link: '/' },
        ...(isAuthenticated
            ? [
                /* { 
                    label: 'Profile', 
                    link: `/management/profile/settings/${me.id}`, 
                    isDynamic: true
                }, */
              ]
            : []),
        { label: 'Sign Up', link: '/register' },
        { label: 'Contact Us', link: '/contact-us' },
    ];

    const toggleDrawer = (open: boolean) => {
        setIsDrawerOpen(open);
    };

    const {t} = useDb();

    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/sign-in');
        window.scrollTo(0, 0);
    };


  return (
    <>
    <PageHelmet />
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1100,
      }}
    >
      <Header menuItems={menuItems} />
    </Box>

    {/* Spazio per compensare l'header fisso */}
        <Box sx={{ height: '150px' }} />

    <Box sx={{ padding: "0rem 0", backgroundImage: 'url(static/images/home/bg.jpg)',
        backgroundRepeat: 'repeat', }}>
        <Grid container spacing={2}>
            <Grid size={12} textAlign="center">
                <Typography variant="h2" 
                sx={{ padding: "3rem 0", 
                mb: 0, 
                textTransform: "uppercase", 
                fontSize: "35px", 
                fontFamily: "'Oswald', sans-serif", 
                fontWeight: 500 }}>About Us</Typography>
                <img src="static/images/home/line.png" alt="Line" />
            </Grid>
        </Grid>
    </Box>
   
    <Box sx={{ padding: "4rem 0", backgroundImage: 'url(static/images/home/bg.jpg)',
    backgroundRepeat: 'repeat', }}>
        <Box>
            <Grid container spacing={2}>
                <Grid size={12} textAlign="center">
                    <Box className="find-movie-content">
                    <Typography variant="h2" sx={{ mb: 2, textTransform: "uppercase", fontFamily: "'Oswald', sans-serif", fontWeight: 500 }}>
                        {t('Find the best Movie Industry Professionals')}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 3, fontSize: "22px",
                    padding: "20px 0",
                    color: "#ff6600", }}>
                        {t('Your platform to connect with best talents outside')}
                    </Typography>
                    <Button style={buttonStyleOrange} onClick={handleClick}>
                        {t('Start Now')}
                    </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        {/* <Box>
            <Grid container spacing={2}>
                <Grid size={12} textAlign="center">
                    <Typography variant="h2" sx={{ padding: "3rem 0", mb: 0, textTransform: "uppercase", fontSize: "35px", fontFamily: "'Oswald', sans-serif", fontWeight: 500 }}>About Us</Typography>
                    <img src="static/images/home/line.png" alt="Line" />
                </Grid>
            </Grid>
        </Box> */}
        {/* <Box sx={{ padding: "3rem 0",}}>
            <Grid container spacing={2}>
                <Grid size={6} sx={{ textAlign: "justify", color: "#fff", padding: "30px" }}>
                    <Typography variant="body1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                    </Typography>
                </Grid>
                <Grid size={6} sx={{ textAlign: "justify", color: "#fff", padding: "30px" }}>
                    <Typography variant="body1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                    </Typography>
                </Grid>
            </Grid>
        </Box> */}

        <Box sx={{ padding: "3rem 0" }}>
            <Container>
                <Grid container spacing={6}>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <Typography variant="body1">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                        </Typography>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <Typography variant="body1">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>                
        <Box sx={{ padding: "2rem 0" }}>
            <Container>
                <Box sx={{ padding: "2rem 0", backgroundImage: 'url(static/images/home/about-bg.jpg)' }}>
                    <Container>
                        <Grid container spacing={4}>
                            <Grid size={{ xs: 12, sm: 6 }}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <img
                                    src="static/images/home/about-img.jpg"
                                    alt="About Us"
                                    style={{ maxWidth: '100%', borderRadius: 8 }}
                                    />
                                </Box>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6 }}>
                                <Box sx={{ padding: 2 }}>
                                    <Typography variant="body1" sx={{ lineHeight: 1.8, color: "#000", fontSize: "16px" }}>
                                    Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Container>
        </Box>
    </Box>
    
    <Footer menuItems={menuItems} />
    </>
  );
};

export default AboutUs;
