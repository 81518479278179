import React, { useState } from "react";
import {
    AppBar,
    Box,
    Toolbar,
    Typography,
    IconButton,
    Drawer,
    List,
    ListItem,
    Link,
    ListItemText,
    Container,
    ListItemButton,
  } from "@mui/material";
  import { Link as RouterLink } from 'react-router-dom';
  import Grid from "@mui/material/Grid2";
  import { Helmet } from "react-helmet-async";
  import { useDb } from "src/contexts/DbContext";
  import MenuIcon from "@mui/icons-material/Menu";
  import HeaderLogin from "../../layouts/SidebarLayout/Header/Buttons/Login";
  import HeaderUserbox from 'src/layouts/SidebarLayout/Header/Userbox';
  import LoginIcon from '@mui/icons-material/Login';
  
  import {useAuth} from "../../contexts/AuthContext";

interface MenuProps {
  menuItems: { label: string; link: string }[];
}

const Header: React.FC<MenuProps> = ({ menuItems }) => {
    const {isAuthenticated} = useAuth();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = (open: boolean) => {
        setIsDrawerOpen(open);
    };
    const {t} = useDb();

  return (
    <React.Fragment>
        {/* <Helmet>
            <title>The Specialist - Home</title>
        </Helmet> */}

        <Box sx={{ backgroundColor: "#ff6600", color: "#fff", padding: "0.3rem 0", }}>
            <Box>
                <Grid container spacing={0} sx={{margin: "0.8rem" }}>
                    <Grid size={{ xs: 6, md: 6, }}>
                        <Typography variant="body1" sx={{padding: "1rem 0", fontFamily: "Oswald, sans-serif"}}>
                        
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        justifyContent="flex-end" // Allineamento orizzontale a destra
                        alignItems="center" // Allineamento verticale al centro
                        size={{ xs: 6, md: 6 }}
                        >
                        {isAuthenticated ? (
                            <HeaderUserbox />

                        ) : (
                            <RouterLink
                            to="/sign-in"
                            style={{
                                color: "#fff", fontSize: "16px",
                                marginRight: "10px",
                                textDecoration: 'none', // Rimuove la sottolineatura
                            }}
                            >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                Login
                                <LoginIcon sx={{ mr: 0 }} />
                               
                            </Box>
                        </RouterLink>
                    )}
                    </Grid>
                </Grid>
            </Box>
        </Box>

        {/* <Box sx={{ backgroundColor: "#ff6600", color: "#fff", padding: "0.8rem 0" }}>
            <Container>
            <Grid container spacing={6}>
                <Grid size={{ xs: 6, md: 6, }}>
                <Typography variant="body1" sx={{padding: "0rem 0"}}>
                    
                </Typography>
                </Grid>
                <Grid size={{ xs: 6, md: 6 }} textAlign="right">
                    <Typography variant="body1" sx={{padding: "0rem 0", color: "#fff"}}>
                        <Link component={RouterLink} to="/" underline="none"
                        sx={{padding: "0rem 0", color: "#fff", fontSize: "16px"}}
                        >
                            {t('Login / Logout')}
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
            </Container>
        </Box> */}

        <Box sx={{ margin: "0rem 0" }}>
            <AppBar position="static" sx={{ backgroundColor: "#000", color: "#fff", padding: "1rem 0" }}>
                <Toolbar sx={{ minHeight: 400, margin: "0.5rem" }}> {/* cambia posizione della toolbar (non perfettamente responsive) sx={{ minHeight: 400, marginLeft: "10%", marginRight: "10%"}} */}
                {/* Title or Logo */}
                <Typography variant="h4" sx={{ flexGrow: 1 }}>
                    <Link component={RouterLink} to="/">
                        <img src={'/static/images/logo/thespecialist.png'} alt="Logo" style={{ maxWidth: "250px" }} />
                    </Link>
                </Typography>

                {/* Mobile Menu Button */}
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ display: { sm: "none" } }}
                    onClick={() => toggleDrawer(true)}
                >
                    <MenuIcon />
                </IconButton>

                <Box
                    sx={{
                        display: { xs: "none", sm: "flex" },
                        gap: 2, fontSize: "18px"
                    }}
                    >

                    {menuItems.map((item) => (
                    <Link
                        component={RouterLink}
                        to={item.link}
                        color="inherit" noWrap
                        underline="none"
                        sx={{
                            padding: "0.8rem", margin: "0rem",
                            "&:hover": {
                            color: "#ff6600", 
                            },
                        }}
                    >
                    {t(item.label)}
                    </Link>
                    ))}
                    </Box>
                </Toolbar>
            </AppBar>

            {/* Drawer for Mobile Menu */}
            <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={() => toggleDrawer(false)}
            >
                <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={() => toggleDrawer(false)}
                onKeyDown={() => toggleDrawer(false)}
                >
                <List>
                    {menuItems.map((item) => (
                    <ListItemButton 
                        component={RouterLink} 
                        to={item.link}
                        >
                        <ListItemText primary={item.label} />
                    </ListItemButton>
                    ))}
                </List>
                </Box>
            </Drawer>
        </Box>
    </React.Fragment>
  );
};

export default Header;