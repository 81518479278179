import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';


const PageHelmet: React.FC = () => {
  const location = useLocation();

  //Titoli e descrizioni route
  const pageDetails = {
    '/': { title: 'The Specialist - Home', description: '' },
    '/about-us': { title: 'The Specialist - About', description: '' },
    '/contact-us': { title: 'The Specialist - Contact', description: '' },
    '/register': { title: 'The Specialist - Sign Up', description: '' },
  };

  //Dettagli pagina in base alla location
  const { title, description } = pageDetails[location.pathname] || {
    title: 'My App',
    description: 'Default description',
  };
  console.log(location.pathname);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
    </>
  );
};

export default PageHelmet;