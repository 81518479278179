import React, { useState } from "react";
import {
    Box,
    Typography,
    IconButton,
    Link,
    Container,
    Button,
    TextField, CardContent
} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import Grid from "@mui/material/Grid2";
import { useDb } from "src/contexts/DbContext";
import { useStyles } from "src/contexts/StylesContext";
import Header from 'src/components/Header';
import Footer from 'src/components/Footer';
import { AccessTime, Email, LocationOn, Phone } from "@mui/icons-material";
import {Helmet} from "react-helmet-async";
import {useLocation, useNavigate} from "react-router-dom";
import ModalItem from "../../components/ModalItem";
import OnBoardingSignIn from "../../components/OnBoardingSignIn";


/* interface HomePageProps {
    isLoggedIn: boolean;
} */

const SignUp: React.FC = () => {
    const { buttonStyleOrange, buttonStyleGreen } = useStyles();
    
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const menuItems = [
        { label: 'Home', link: '/' },
        { label: 'About Us', link: '/about-us' },
        { label: 'Contact Us', link: '/contact-us' },
    ];

    const toggleDrawer = (open: boolean) => {
        setIsDrawerOpen(open);
    };

    const location = useLocation();
    const navigate = useNavigate();

    const {t} = useDb();
    const queryParams = new URLSearchParams(location.search);
    const openDialog = queryParams.get("popup");
    const [popupOpen, setPopupOpen] = useState<boolean>(openDialog ? (openDialog === 'true') : false);
    const [onboardingProcess, setOnboardingProcess] = useState<number>(1);


  return (
    <>
    <Header menuItems={menuItems} />
    
    <Helmet>
        <title>{t('The Specialist - Sign up')}</title>
    </Helmet>

    {popupOpen && (
        <ModalItem open={popupOpen} onClose={() => { setPopupOpen(false); navigate('/register') }}>
            <CardContent>
                <Box sx={{ flexGrow: 1 }} display="flex" flexDirection="column">
                    <Typography variant="h2" sx={{ mt: 2, fontFamily: 'Oswald, sans-serif', textAlign: 'center' }}>
                        <span>{t('Sign up to The Specialist')}</span>
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 5, fontFamily: 'Oswald, sans-serif' }}>
                        <span>{t('Did you find an interesting profile?')}</span>
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2, fontFamily: 'Oswald, sans-serif' }}>
                        <span>{t('Sign up to The Specialist to search contents and people')}</span>
                    </Typography>
                </Box>
            </CardContent>
        </ModalItem>
    )}


    <Box sx={{ padding: "0rem 0", backgroundImage: 'url(static/images/home/bg.jpg)',
        backgroundRepeat: 'repeat', }}>
        <Grid container spacing={2}>
            <Grid size={12} textAlign="center">
                <Typography variant="h2" 
                sx={{ padding: "3rem 0", 
                mb: 0, 
                textTransform: "uppercase", 
                fontSize: "35px", 
                fontFamily: "'Oswald', sans-serif", 
                fontWeight: 500 }}>{t('Sign Up')}</Typography>
                <img src="static/images/home/line.png" alt="Line" />
            </Grid>
        </Grid>
    </Box>

    <Box component="section" sx={{ py: 2, backgroundImage: 'url(static/images/home/bg.jpg)',
        backgroundRepeat: 'repeat', }}>
      <Container>
        <Grid container justifyContent="center" spacing={10}>
          <Grid size={{ xs: 12, sm: 8 }}>
            <Box
              sx={{
                p: 4, 
                border: "2px solid #fff",
                borderRadius: 2,
                boxShadow: 3,
              }}
            >
              <OnBoardingSignIn OnChangeOnboardingStep={(procnum)=>setOnboardingProcess(procnum)} />
            </Box>
            {/* <Box
              sx={{
                textAlign: "center",
                mt: 3,
              }}
            >
              <Typography variant="h4">or Sign in with</Typography>
              <Box sx={{ mt: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Link href="#" sx={{ mx: 1 }}>
                  <img
                    src="static/images/home/fd.png"
                    alt="Facebook"
                    style={{ height: 50 }}
                  />
                </Link>
                <Link href="#" sx={{ mx: 1 }}>
                  <img
                    src="static/images/home/gmail.png"
                    alt="Google"
                    style={{ height: 50 }}
                  />
                </Link>
              </Box>
            </Box> */}
          </Grid>
        </Grid>
      </Container>
    </Box>

    
    <Footer menuItems={menuItems} />
    </>
  );
};

export default SignUp;
