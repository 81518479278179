import React, { useState } from "react";
import {
    Box,
    Typography,
    IconButton,
    Link,
    Container,
    Button,
    TextField
} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import Grid from "@mui/material/Grid2";
import { useDb } from "src/contexts/DbContext";
import { useStyles } from "src/contexts/StylesContext";
import Header from 'src/components/Header';
import Footer from 'src/components/Footer';
import { AccessTime, Email, LocationOn, Phone } from "@mui/icons-material";
import Login from "../../components/Login";


/* interface HomePageProps {
    isLoggedIn: boolean;
} */

const SignIn: React.FC = () => {
    const { buttonStyleOrange, buttonStyleGreen } = useStyles();
    
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const menuItems = [
        { label: 'Home', link: '/' },
        { label: 'About Us', link: '/about-us' },
        { label: 'Contact Us', link: '/contact-us' },
    ];

    const toggleDrawer = (open: boolean) => {
        setIsDrawerOpen(open);
    };

    const {t} = useDb();

    const [login, setLogin] = useState<boolean>(true);


  return (
    <>
    <Header menuItems={menuItems} />
    

    <Box sx={{ padding: "0rem 0", backgroundImage: 'url(static/images/home/bg.jpg)',
        backgroundRepeat: 'repeat', }}>
        <Grid container spacing={2}>
            <Grid size={12} textAlign="center">
                <Typography variant="h2" 
                sx={{ padding: "3rem 0", 
                mb: 0, 
                textTransform: "uppercase", 
                fontSize: "35px", 
                fontFamily: "'Oswald', sans-serif", 
                fontWeight: 500 }}>Sign In</Typography>
                <img src="static/images/home/line.png" alt="Line" />
            </Grid>
        </Grid>
    </Box>

    <Box component="section" sx={{ py: 4, backgroundImage: 'url(static/images/home/bg.jpg)',
        backgroundRepeat: 'repeat', }}>
      <Container>
        <Grid container justifyContent="center" spacing={20}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Box
              sx={{
                p: 4, 
                border: "2px solid #fff",
                borderRadius: 2,
                boxShadow: 3,
              }}
            >
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <Link href="#" sx={{ textDecoration: "none" }}>
                  Sign Up
                </Link>
                <Link href="#" sx={{ textDecoration: "none" }}>
                  Sign In
                </Link>
              </Box> */}
              {/* <Box component="form"> */}
                {/* <Box sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    id="fname"
                    name="fname"
                    label="Name"
                    variant="standard"
                  />
                </Box>
                <Box sx={{ mb: 5 }}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    variant="standard"
                  />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    id="phone"
                    name="tel"
                    label="Mobile No"
                    variant="standard"
                  />
                </Box>
                <Box sx={{ mb: 5 }}>
                  <TextField
                    fullWidth
                    type="password"
                    id="password"
                    name="psw-repeat"
                    label="Password"
                    variant="standard"
                  />
                </Box>
                <Box sx={{ textAlign: "center", mb: 5 }}>
                  <Button type="submit" variant="contained" color="primary" fullWidth sx={{color: "#fff", textTransform: "uppercase", fontFamily: "'Oswald', sans-serif", fontWeight: 500}}>
                    Sign In
                  </Button>
                </Box> */}

                {login && (<Login/>)}

                <Typography variant="body2" align="center">
                    Don't have an account? {" "}
                  <Link component={RouterLink} to="/register" sx={{ textDecoration: "none" }}>
                    Sign Up
                  </Link>
                </Typography>
              {/* </Box> */}

            </Box>

            <Box
              sx={{
                textAlign: "center",
                mt: 3,
              }}
            >
              <Typography variant="h4">or Sign in with</Typography>
              <Box sx={{ mt: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Link href="#" sx={{ mx: 1 }}>
                  <img
                    src="static/images/home/fd.png"
                    alt="Facebook"
                    style={{ height: 50 }}
                  />
                </Link>
                <Link href="#" sx={{ mx: 1 }}>
                  <img
                    src="static/images/home/gmail.png"
                    alt="Google"
                    style={{ height: 50 }}
                  />
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
    
    <Footer menuItems={menuItems} />
    </>
  );
};

export default SignIn;
