import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

/* import ProfileNewStyle from 'src/layouts/SidebarLayout'; */
import ProfileNewStyle from 'src/pages/profile';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import ProtectedRoute from "./components/ProtectedRoute"; 
/* import HomePage from "./pages/home"; */ //old graphics

//Test nuova grafica
import HomePage from "./pages/home";
import AboutUs from "./pages/about-us";
import ProfileNew from "./pages/profile";
import ContactUs from "./pages/contact-us";
import SignIn from "./pages/sign-in";
import OnBoarding from "./pages/sign-up";

/* import OnBoarding from "./pages/onboarding"; */

import SubscriptionPage from "./pages/subscription";
import ProfileDetails from "./pages/profile";
import ProjectDetails from "./pages/project";
import ContentDetails from "./pages/content";
import CompletePage from "./pages/subscription/status/Complete";
import CheckoutForm from "./pages/subscription/CheckoutForm";
import StripePayment from "./pages/subscription/StripePayment";
import {DeletedPage} from "./pages/users/delete";
import {PendingUsersTable} from "./pages/dashboards/admin/users/PendingUsersTable";


const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );


const Home = Loader(lazy(() => import('src/pages')));
const UserProfile = Loader(
  lazy(() => import('src/pages/users/profile'))
);
const Test = Loader(lazy(() => import('src/pages/test')));
const UserSettings = Loader(
  lazy(() => import('src/pages/users/settings'))
);
const Profile = Loader(lazy(() => import('src/pages/profile')));
const Project = Loader(lazy(() => import('src/pages/project')));
const Content = Loader(lazy(() => import('src/pages/content')));
//const Subscription = Loader(lazy(() => import('src/pages/subscription')));

// Status
const Status404 = Loader(
  lazy(() => import('src/pages/status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/pages/status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/pages/status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/pages/status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element:  <HomePage isLoggedIn={false} />
      },
      {
        path: 'users',
        children: [
          {
            path: ':id/delete',
            element: <DeletedPage/>
          },
        ]
      },
      {
        path: 'subscribe',
        children: [
          {
            path: '',
            element:  <SubscriptionPage/>,
          },
          {
            path: 'complete',
            element: <CompletePage/>
          },
          {
            path: 'checkout/:id',
            element: <StripePayment/>
          },
        ]
      },
      {
        path: 'pages',
        element: <Navigate to="/" replace />
      },
      {
        path: 'about-us',
        element: <AboutUs />
      },
      {
        path: 'contact-us',
        element: <ContactUs />
      },
      {
        path: 'sign-in',
        element: <SignIn />
      },
      {
        path: 'profile',
        element: <Profile />
      },
      {
        path: 'register',
        element: <OnBoarding />
      },
      {
        path: 'content/details/:id',
        element: <ProtectedRoute><ContentDetails /></ProtectedRoute>
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element:  <ProtectedRoute><ProfileNewStyle /></ProtectedRoute>,
    children: [
      {
        path: '',
        element: <ProtectedRoute><PendingUsersTable /></ProtectedRoute>
      },
    ]
  },
  {
    path: 'test',
    element:  <ProtectedRoute><ProfileNewStyle /></ProtectedRoute>,
    children: [
      {
        path: '',
        element: <ProtectedRoute><Test /></ProtectedRoute>
      }
    ]
  },
  {
    path: 'profile',
    element:  <ProtectedRoute><ProfileNewStyle /></ProtectedRoute>,
    children: [
      {
        path: 'details/:id',
        element:  <ProtectedRoute><ProfileDetails /></ProtectedRoute>
      },
    ]
  },
  {
    path: 'project',
    element:  <ProtectedRoute><ProfileNewStyle /></ProtectedRoute>,
    children: [
      {
        path: 'details/:id',
        element:  <ProtectedRoute><ProjectDetails /></ProtectedRoute>
      },
    ]
  },
  {
    path: 'management',
    element:  <ProtectedRoute><ProfileNewStyle /></ProtectedRoute>,
    children: [
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <ProtectedRoute><Navigate to="details" replace /></ProtectedRoute>
          },
          {
            path: 'details/:id',
            element:  <ProtectedRoute><UserProfile /></ProtectedRoute>
          },
          {
            path: 'settings/:id',
            element:  <ProtectedRoute><UserSettings /></ProtectedRoute>
          }
        ]
      }
    ]
  }
];

export default routes;
