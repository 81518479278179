import React, { useState } from "react";
import {
    Box,
    Typography,
    IconButton,
    Link,
    Container,
    Button,
    TextField
} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Grid from "@mui/material/Grid2";
import { useDb } from "src/contexts/DbContext";
import { useStyles } from "src/contexts/StylesContext";
import Header from 'src/components/Header';
import Footer from 'src/components/Footer';
import HeaderSearch from 'src/layouts/SidebarLayout/Header/Buttons/Search';
import HomeSearch from 'src/layouts/SidebarLayout/Header/Buttons/Search/HomePage';
import {useAuth} from "../contexts/AuthContext";
import PageHelmet from '../components/PageHelmet';

interface HomePageProps {
    isLoggedIn: boolean;
}

const HomePage: React.FC<HomePageProps> = ({ isLoggedIn }) => {
    const { buttonStyleOrange, buttonStyleGreen } = useStyles();

    const {me, isAuthenticated} = useAuth();
    
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const menuItems = [
        { label: 'About Us', link: '/about-us' },
        ...(isAuthenticated
          ? [
              /* { label: 'Profile', link: '/profile' }, */
             /*  { 
                label: 'Profile', 
                link: `/management/profile/settings/${me.id}`, 
                isDynamic: true
              }, */
            ]
          : []),
          { label: 'Sign Up', link: '/register' },
          { label: 'Contact Us', link: '/contact-us' },
      ];

    const toggleDrawer = (open: boolean) => {
        setIsDrawerOpen(open);
    };

    const {t} = useDb();

    const navigate = useNavigate();
    
    /* const handleClick = () => {
        navigate('/sign-in');
        window.scrollTo(0, 0);
    }; */

    const handleClick = (path: string) => {
      navigate(path);
      window.scrollTo(0, 0);
    };


  return (
    <>
    <PageHelmet />
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1100,
      }}
    >
      <Header menuItems={menuItems} />
    </Box>

    {/* Spazio per compensare l'header fisso */}
    <Box sx={{ height: '150px' }} />

   
    {/* Search Profile Section */}
    <Box sx={{
        backgroundImage: 'url(static/images/home/banner-bg.jpg)',
        backgroundRepeat: 'no-repeat',
        height: '500px',
      }}>
        <Box className="container">
          <Grid container spacing={0}>
            <Grid size={12}>
              <Box className="search-profile-content" sx={{
                textAlign: 'center', 
                color: '#ffffff', 
                paddingTop: '9%'
                }}>
                
                <Typography variant="h1" sx={{ mb: 2, textTransform: "uppercase", paddingBottom: '20px', fontFamily: "'Oswald', sans-serif", fontWeight: 500}}>
                {t('Search the best')} <span style={{ color: '#ff6600' }}>{t('Movie Industry')}</span> {t('Professionals')}
                </Typography>
                {/* <form>
                <TextField
                    fullWidth 
                    variant="outlined"
                    placeholder="Search Here......."
                    sx={{
                    backgroundColor: '#000000',
                    width: '500px',
                    padding: '0px',
                    border: 'solid 2px #ff6600',
                    borderRadius: '13px',
                    fontSize: '16px',
                    color: '#ffffff',
                    maxWidth: '90%',
                    }}
                />
                </form> */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center', // Centra orizzontalmente
                        /* alignItems: 'center', */ // Centra verticalmente
                        width: '100%', // Occupa tutta la larghezza disponibile
                        /* height: '100vh', */ // Occupa tutta l'altezza dello viewport
                    /*  backgroundColor: 'transparent', */ // Sfondo trasparente
                    }}
                    >
                    <Box sx={{ width: '500px', padding: '10px'}}>
                        <HomeSearch />
                    </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Find Movie Section */}
      <Box sx={{ padding: "4rem 0", maxWidth: '100%', backgroundImage: 'url(static/images/home/bg.jpg)',
        backgroundRepeat: 'repeat', }}>
        <Box className="container">
          <Grid container spacing={2}>
            <Grid size={12} textAlign="center">
              <Box className="find-movie-content">
                <Typography variant="h2" sx={{ mb: 2, textTransform: "uppercase", fontSize: "38px", fontFamily: "'Oswald', sans-serif", fontWeight: 500 }}>
                  {t('Find the best Movie Industry Professionals')}
                </Typography>
                <Typography variant="body1" sx={{ mb: 3, fontSize: "22px",
                padding: "20px 0",
                color: "#ff6600", }}>
                  {t('Your platform to connect with best talents outside')}
                </Typography>
                <Button style={buttonStyleOrange} onClick={() => handleClick('/sign-in')}>
                    Start Now &gt;&gt;
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Contact Specialist Section */}
      <Box sx={{ padding: "0rem 0", backgroundColor: "#000" }}>
        <Box>
          <Grid container spacing={3}>
           
            {/* Images */}
            <Grid container size={12} spacing={2} textAlign="center">
                <Grid size={{ xs: 12, sm: 4 }}>
                    <Box className="contact-specilist-img" sx={{background: "#000000", padding: "20px"}}>
                        <img src="static/images/home/movie-img-1.jpg" alt="Movie 1" style={{ width: "100%", borderRadius: "20px" }} />
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 4 }}>
                    <Box className="contact-specilist-img" sx={{background: "#000000", padding: "20px",}}>
                        <img src="static/images/home/movie-img-2.jpg" alt="Movie 2" style={{ width: "100%", borderRadius: "20px" }} />
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 4 }}>
                    <Box className="contact-specilist-img" sx={{background: "#000000", padding: "20px",}}>
                        <img src="static/images/home/movie-img-3.jpg" alt="Movie 3" style={{ width: "100%", borderRadius: "20px" }} />
                    </Box>
                </Grid>
            </Grid>

            <Grid size={12} textAlign="center">
              <Box className="contact-specilist-btn" sx={{textAlign: "center",
                paddingTop: "10px", paddingBottom: "50px",}}>
                <Button style={buttonStyleOrange} onClick={() => handleClick('/contact-us')}>
                    {t('Contact the Specialist')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    
      <Footer menuItems={menuItems} />
    </>
  );
};

export default HomePage;
