import React, { useState } from "react";
import {
    AppBar,
    Box,
    Toolbar,
    Typography,
    IconButton,
    Drawer,
    List,
    ListItem,
    Link,
    ListItemText,
    Container,
    ListItemButton,
  } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import Grid from "@mui/material/Grid2";
import { useDb } from "src/contexts/DbContext";
import { Facebook, Instagram, Pinterest, Twitter, YouTube, X } from '@mui/icons-material';


interface MenuProps {
  menuItems: { label: string; link: string }[];
}

const Footer: React.FC<MenuProps> = ({ menuItems }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    
    const handleClick = () => {
        window.scrollTo(0, 0);
    };

    const toggleDrawer = (open: boolean) => {
        setIsDrawerOpen(open);
    };
    const {t} = useDb();

  return (
    <React.Fragment>
        <Box sx={{ color: "#fff" }}>
            {/* <Container> */}
                <Grid container spacing={0}>
                    <Grid size={{ xs: 12, sm: 6}} 
                        sx={{
                        backgroundColor: "#709a02", 
                        color: "#000", padding: "2rem 0", 
                        fontFamily: "'Oswald', sans-serif",
                        fontSize: "25px", 
                        textTransform: "uppercase",
                        fontWeight: 500}}>
                        <Box textAlign="center" sx={{ flex: 1 }}> 
                            <Link href="#" underline="none" color="inherit">
                            FOLLOW US ON LinkedIn
                            </Link>
                        </Box>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6}} 
                        sx={{
                        backgroundColor: "#ff6600", 
                        color: "#000", padding: "2rem 0", 
                        fontFamily: "'Oswald', sans-serif",
                        fontSize: "25px", 
                        textTransform: "uppercase",
                        fontWeight: 500}}>
                        <Box textAlign="center" sx={{ flex: 1 }}> 
                            <Link href="#" underline="none" color="inherit">
                            FOLLOW US ON youtube
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            {/* </Container> */}
        </Box>
        {/* Social Icons Section */}
    <Box display="flex" flexDirection="column" alignItems="left" textAlign="left" py={4}>
        <Container>
        <Grid container spacing={0} >
                <Grid size={{ xs: 12, sm: 2}} justifyContent="flex-end">
                    <Typography sx={{padding: "0.5rem", fontSize: "20px"}}>
                        Connect with us
                    </Typography>
                </Grid>
                <Grid size={{ xs: 12, sm: 10}} justifyContent="flex-end">
                    <Box display="flex" justifyContent="left" gap={2}>
                        <IconButton component="a" href="#" aria-label="Facebook" sx={{ color: '#3b5998', borderRadius: "50%" }}>
                            <Facebook sx={{ fontSize: "2rem" }}/>
                        </IconButton>
                        <IconButton component="a" href="#" aria-label="Instagram" sx={{ color: '#305777', borderRadius: "50%" }}>
                            <Instagram sx={{fontSize: "2rem"}} />
                        </IconButton>
                        <IconButton component="a" href="#" aria-label="Pinterest" sx={{ color: '#cb2027', borderRadius: "50%" }}>
                            <Pinterest sx={{fontSize: "2rem"}} />
                        </IconButton>
                        <IconButton component="a" href="#" aria-label="Twitter" sx={{ color: '#00aced', borderRadius: "50%" }}>
                            <Twitter sx={{fontSize: "2rem"}} />
                        </IconButton>
                        {/* <IconButton component="a" href="#" aria-label="X" sx={{ color: '#00aced', borderRadius: "50%" }}>
                            <X sx={{fontSize: "2rem"}} />
                        </IconButton> */}
                        <IconButton component="a" href="#" aria-label="YouTube" sx={{ color: '#c4302b', borderRadius: "50%" }}>
                            <YouTube sx={{fontSize: "2rem"}} />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    </Box>

    <Box sx={{ backgroundColor: "#0c0c0c", color: "#fff", padding: "2rem 0" }}>
      <Container>
        <Grid container spacing={6}>
          <Grid size={{ xs: 12, sm: 4 }}>
            <Typography variant="h4" sx={{ mb: 2 }}>About Us</Typography>
            <Typography variant="body1">
            {t('Our dining room with barrel vaults and sandstone pillars offers a fairytale setting.')}
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <Typography variant="h4" sx={{ mb: 2}}>{t('Address')}</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>TheSpecialist, Schönnewitz 9, 01665 Käbschützal</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>{t('Tel:')} 0175 - 169 76 45</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>Email: info@thespecialist</Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <Typography variant="h4" sx={{ mb: 2}}>{t('Information')}</Typography>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {menuItems.map((item) => (
                <Box textAlign="left" marginBottom="1rem">
                <li>
                  <Link 
                    component={RouterLink}
                      to={item.link}
                      onClick={handleClick}
                      color="inherit" underline="none" sx={{
                      fontSize: "1rem",
                      "&:hover": {
                        color: "#ff6600", fontWeight: 800,
                      },
                    }}>
                    {t(item.label)}
                  </Link>
                </li>
                </Box>
              ))}
            </ul>
          </Grid>
        </Grid>
        
      </Container>
    </Box>
    <Box textAlign="center" marginTop="0rem" sx={{background: "#000000", padding: "1rem 0"}}>
      <Typography variant="body2">&copy; 2025 TheSpecialist</Typography>
    </Box>
    </React.Fragment>
  );
};

export default Footer;